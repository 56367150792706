// Spain
export const locale = {
  frequencies: {
    monthly: 'Monthly',
    trimester: 'Quarterly',
    semiannual: 'Semi-annual'
  },
  plansCountries: {
    colombia: { label: 'National plans' },
    international: { label: 'International plans' }
  },
  complements: {
    titles: {
      sms: 'Text messages',
      whatsapp: 'Whatsapp',
      email: 'Email',
      payroll: 'ELECTRONIC PAYROLL',
      electronic_documents: 'INVOICING, ELECTRONIC PAYROLL AND SUPPORTING DOCUMENT'
    },
    sms_wa_plans: {
      plan_500: '500 Message Plan',
      plan_1000: '1.000 Message Plan',
      plan_2000: '2.000 Message Plan',
      plan_3000: '3.000 Message Plan',
      plan_5000: '5.000 Message Plan',
      plan_10000: '10.000 Message Plan',
      plan_20000: '20.000 Message Plan'
    },
    featuresPayroll: {
      feature_1: 'Integration of the Electronic Payroll Document Issuance Module.',
      feature_2: 'Support, updating and adjustments to the issuance model.',
      feature_3: 'Support.',
      feature_4: '1 Headquarters or branch.',
      feature_5: 'Monthly or annual service.'
    },
    featuresElectronicDocuments: {
      feature_1: 'Integration of the module for Electronic Invoice Issuance and Electronic Payroll Issuance.',
      feature_2: 'Integration of the module for Support Document Issuance.',
      feature_3: 'Support, updating, adjustments to the issuance and reception model.',
      feature_4: 'Support.',
      feature_5: '1 Headquarters or branch.',
      feature_6: 'Monthly or annual service.'
    }
  },
  plans: {
    titles: {
      planLizto: '¡Plan Lizto!',
      mainTitle2: 'Welcome to Lizto!',
      entrepreneur: 'Entrepreneur',
      professional: 'Professional',
      premium: 'Premium',
      max: 'Max',
      retail: 'Retail',

      whatsappTitle: 'Whatsapp',
      smsTitle: 'SMS',
      complementsTitle: 'Complement your plans',
      complementsSubTitle: 'Optimize your performance by integrating tools to make managing your business easier.'
    },
    buttons: {
      select1: 'National plans',
      select2: 'International plans',
      radioButton1: 'Monthly plans',
      radioButton2: 'Annual Plans',
      wantItButton: 'I want it now!'
    },
    mostSeller: 'Best seller!',
    mostPopular: 'Most Popular!',
    notAvailable: 'No Available',
    max: {
      liztoMaxCompanies: 'Lizto Max Enterprises',
      designedSolution: 'The solution designed for large companies',
      infoMassive: 'Massive, agile and secure information',
      liztoCompaines: 'Lizto Enterprises unifies agile solutions that generate impact and make you competitive in the face of constant changes in the market.',
      buttonAdvice: 'Request Advice',
      buttonPurchase: 'Buy plan'
    },
    price: 'Price',
    plansFeatures: {
      priceTitles: {
        titleMonthly: 'Price per monthly venue',
        titleAnual: 'Price per annual venue'
      },
      allFeatures: {
        nameBox: {
          title: 'POS system and electronic billing',
          details: {
            feature_1: 'Integration for electronic billing and electronic POS',
            feature_2: 'Service billing',
            feature_3: 'Product billing',
            feature_4: 'Billing and redemption of vouchers',
            feature_5: 'Pending collections',
            feature_6: 'Withdraw money (Advances, expenses, withdrawal)',
            feature_7: 'Returns',
            feature_8: 'Movements',
            feature_9: 'Express cash register',
            feature_10: 'Plans and combos',
            feature_11: 'Business account',
            feature_12: 'Opening and closing cash register',
            feature_13: 'Sales summary',
            feature_14: 'Payment receipts (Credits)',
            feature_15: 'Sales summary (Detailed and summarized)',
            feature_16: 'Loyalty points',
            feature_17: 'Digital orders and service orders',
            feature_18: 'Daily cash register closing reports and by cashier user'
          }
        },
        nameScheduling: {
          title: 'Agenda',
          details: {
            feature_1: 'Online scheduling and reservations through social networks',
            feature_2: 'Email reminders',
            feature_3: 'WhatsApp reminders, SMS (Packages) WhatsApp API',
            feature_3_1: 'WhatsApp API Integration Gift'
          }
        },
        nameServices: {
          title: 'Services',
          details: {
            feature_1: 'Service Management',
            feature_2: 'Service Reminders (Opportunities)',
            feature_3: 'Consumer products. Function to make product ties in services',
            feature_4: 'BAC Mode - Beta'
          }
        },
        nameStaff: {
          title: 'Staff',
          details: {
            feature_1: 'Commission management for the sale of services',
            feature_2: 'Commission management for the sale of products',
            feature_3: 'News from Staff',
            feature_4: 'Specialist credits',
            feature_5: 'Administrative discounts for Staff'
          }
        },
        nameCustomers: {
          title: 'Clients',
          details: {
            feature_1: 'Client database',
            feature_2: 'Client history',
            feature_3: 'Medical records and digital informed consents',
            feature_4: 'Loyalty program with points',
            feature_5: 'Work staff and users by location',
            feature_6: 'Satisfaction surveys',
            feature_6_1: '(Campaigns)',
            feature_7: 'Detailed and general reports',
            feature_8: 'Interfaces (Siigo, WO)',
            feature_8_1: 'Quote template',
            feature_9: 'Campaigns'
          }
        },
        nameInventories: {
          title: 'Inventories',
          details: {
            feature_1: 'Purchases from suppliers',
            feature_2: 'Inventory reporting and analytics',
            feature_3: 'Product management',
            feature_4: 'Inventories',
            feature_5: 'Product transfers',
            feature_6: 'Product movements (Inputs and Outputs)',
            feature_7: 'Consumer Products. Function to make product bindings in services.'
          }
        },
        nameInterfaces: {
          title: 'Interfaces',
          details: {
            feature_1: 'Interfaces (Siigo, WO)'
          }
        },
        nameSupport: {
          title: 'Support',
          details: {
            feature_1: 'Training and technical support (Monday to Saturday 8am to 6pm) '
          }
        }
        /*
        nameCampaigns: {
          title: 'Campañas',
          details: {
            feature_1: ''
          }
        },
        nameReports: {
          title: 'Informes.',
          details: {
            feature_1: ''
          }
        },
        nameCommission: {
          title: 'Gestión de comisiones por venta de servicios y productos.',
          details: {
            feature_1: ''
          }
        },
        nameManagement: {
          title: 'Gestión de clientes.',
          details: {
            feature_1: ''
          }
        },
        nameInventory: {
          title: 'Control de Inventarios y Compras.',
          details: {
            feature_1: ''
          }
        },
        nameLoyalty: {
          title: 'Programa de fidelización con puntos.',
          details: {
            feature_1: ''
          }
        },
        nameSatisfaction: {
          title: 'Encuestas de satisfacción',
          details: {
            feature_1: ''
          }
        },
        nameReminder: {
          title: 'Recordatorios automáticos de cita vía mensaje de texto SMS, Whatsapp y Email.',
          details: {
            feature_1: ''
          }
        },
        nameDigitalOrders: {
          title: 'Comandas y órdenes de servicio digitales. (Aplicación web para los especialistas)',
          details: {
            feature_1: ''
          }
        },
        nameBillingPos: {
          title: 'Integración para emisión de facturación electrónica y POS Electrónico. ( Documentos electrónicos es adicional )',
          details: {
            feature_1: ''
          }
        }
        */
      },
      sideFeatures: {
        staffUntil5: 'Until 5',
        staffUntil10: 'Until 10',
        staffUntil30: 'Until 30',
        staffUnlimited: 'Unlimited',
        reminderEmail: 'Email',
        reminderEmailSms: 'Email',
        reminderApiSmsEmail: 'Email'
      }
    },
    currency: {
      monthlyNational: 'Monthly',
      anualNational: 'Anual',
      usd: 'USD'
    },
    complements: {
      whatsappGeneralDescription: 'You can purchase an additional package of WhatsApp messages to activate them on the Professional, Premium and Max plans',
      whatsappGeneralDescriptionInternational: 'You can purchase an additional package of WhatsApp messages to activate them on the Professional and Premium plans',
      whatsappDisclamerTitle: '*Message packages do not expire.',
      whatsappDisclamerText: 'They only expire until the number of messages you have in your account is exhausted, when you exhaust the number of messages you must recharge your account again by acquiring a new package of WhatsApp messages.',
      whatsappTitles: {
        title3000: 'Plan 3.000',
        title2000: 'Plan 2.000',
        title1000: 'Plan 1.000',
        title5000: 'Plan 5.000',
        title10000: 'Plan 10.000'
      },
      whatsappDescription: {
        description3000: '3.000 messages',
        description2000: '2.000 messages',
        description1000: '1.000 messages',
        description5000: '5.000 messages',
        description10000: '10.000 messages'
      },
      smsDisclamerTitle: '*Message packets do not expire.',
      smsDisclamerText: 'They only expire until the number of messages you have in your account is exhausted, when you exhaust the number of messages you must recharge your account again by purchasing a new package of SMS messages.',
      smsTitles: {
        title500: 'Plan 500',
        title1000: 'Plan 1.000',
        title3000: 'Plan 3.000',
        title5000: 'Plan 5.000',
        title10000: 'Plan 10.000',
        title20000: 'Plan 20.000'
      },
      smsDescription: {
        description500: '500 Messages',
        description1000: '1.000 Messages',
        description3000: '3.000 Messages',
        description5000: '5.000 Messages',
        description10000: '10.000 Messages',
        description20000: '20.000 Messages'
      }
    }

  },
  base: {
    documentTitle: 'Lizto - Software for beauty and aesthetic center',
    menu: {
      home: 'Home',
      features: 'Features',
      loginLizto: 'Login to Lizto',
      contact: 'Contact',
      plans: 'Plans'
    },
    text: {
      safeSite: 'Safe Site',
      whatsappText: 'How can we help you?'
    }
  },
  contactFormComplte: {
    text1: 'Thank you',
    text2: 'We will contact you soon',
    buttons: {
      register: 'Return to website'
    }
  },
  banner: {
    text1: 'Easy and efficient software',
    text2: 'for hairdressers, beauty centers, barbershops and spa',
    buttons: {
      register: 'Sign up'
    }
  },
  companyTypes: {
    section: {
      title: 'Lizto adapts very easily to your business'
    },
    names: {
      hairdressers: 'Hairdressers',
      barbershops: 'Barbershops',
      eyebrow_and_lash_spa: 'Eyebrow and Lash spa',
      nail_salon: 'Nail Salon',
      // spa: 'Spa',
      hair_spa_salon: 'Hair spa salon'
    }
  },
  common: {
    text: {
      sendingMessage: 'We are sending your message.',
      errorHasOccurred: 'An error has occurred'
    }
  },
  customers: {
    section: {
      title: 'Our clients'
    }
  },
  features: {
    section: {
      title: 'Features'
    },
    names: {
      calendar: 'Calendar',
      onlineBooking: 'Online Booking',
      inventories: 'Inventories',
      loyalty: 'Loyalty',
      pos: 'POS',
      reports: 'Reports',
      expenseControl: 'Expense Control',
      manageMultipleLocations: 'Manage Multiple Locations'
    }
  },
  pricing: {
    section: {
      title: 'Pricing'
    }
  },
  contact: {
    links: {
      termsAndConditions: 'Terms and Conditions'
    },
    section: {
      title: 'Contact us'
    },
    form: {
      fields: {
        name: 'Full Name*',
        email: 'Email*',
        cellphone: 'Cellphone*',
        message: 'Message*',
        acceptTermsAndConditions: 'Do you accept the use of your personal data in accordance with Law 1581 of 2012 and related regulations and personal data protection policies in order to subscribe to our database for commercial and analytical purposes?',
        policyProcessingPersonalData: 'Personal Data Processing Policy*',
        send: 'Send'
      }
    }
  },
  countries: {
    af: 'Afghanistan',
    al: 'Albania',
    dz: 'Algeria',
    as: 'American Samoa',
    ad: 'Andorra',
    ao: 'Angola',
    ai: 'Anguilla',
    ag: 'Antigua and Barbuda',
    ar: 'Argentina',
    am: 'Armenia',
    aw: 'Aruba',
    au: 'Australia',
    at: 'Austria',
    az: 'Azerbaijan',
    bs: 'Bahamas',
    bh: 'Bahrain',
    bd: 'Bangladesh',
    bb: 'Barbados',
    by: 'Belarus',
    be: 'Belgium',
    bz: 'Belize',
    bj: 'Benin',
    bm: 'Bermuda',
    bt: 'Bhutan',
    bo: 'Bolivia',
    ba: 'Bosnia and Herzegovina',
    bw: 'Botswana',
    br: 'Brazil',
    io: 'British Indian Ocean Territory',
    vg: 'British Virgin Islands',
    bn: 'Brunei',
    bg: 'Bulgaria',
    bf: 'Burkina Faso',
    bi: 'Burundi',
    kh: 'Cambodia',
    cm: 'Cameroon',
    ca: 'Canada',
    cv: 'Cape Verde',
    bq: 'Caribbean Netherlands',
    ky: 'Cayman Islands',
    cf: 'Central African Republic',
    td: 'Chad',
    cl: 'Chile',
    cn: 'China',
    cx: 'Christmas Island',
    cc: 'Cocos',
    co: 'Colombia',
    km: 'Comoros',
    cd: 'Congo',
    cg: 'Congo',
    ck: 'Cook Islands',
    cr: 'Costa Rica',
    ci: 'Côte d’Ivoire',
    hr: 'Croatia',
    cu: 'Cuba',
    cw: 'Curaçao',
    cy: 'Cyprus',
    cz: 'Czech Republic',
    dk: 'Denmark',
    dj: 'Djibouti',
    dm: 'Dominica',
    ec: 'Ecuador',
    eg: 'Egypt',
    sv: 'El Salvador',
    gq: 'Equatorial Guinea',
    er: 'Eritrea',
    ee: 'Estonia',
    et: 'Ethiopia',
    fk: 'Falkland Islands',
    fo: 'Faroe Islands',
    fj: 'Fiji',
    fi: 'Finland',
    fr: 'France',
    gf: 'French Guiana',
    pf: 'French Polynesia',
    ga: 'Gabon',
    gm: 'Gambia',
    ge: 'Georgia',
    de: 'Germany',
    gh: 'Ghana',
    gi: 'Gibraltar',
    gr: 'Greece',
    gl: 'Greenland',
    gd: 'Grenada',
    gp: 'Guadeloupe',
    gu: 'Guam',
    gt: 'Guatemala',
    gg: 'Guernsey',
    gn: 'Guinea',
    gw: 'Guinea-Bissau',
    gy: 'Guyana',
    ht: 'Haiti',
    hn: 'Honduras',
    hk: 'Hong Kong',
    hu: 'Hungary',
    is: 'Iceland',
    in: 'India',
    id: 'Indonesia',
    ir: 'Iran',
    iq: 'Iraq',
    ie: 'Ireland',
    im: 'Isle of Man',
    il: 'Israel',
    it: 'Italy',
    jm: 'Jamaica',
    jp: 'Japan',
    je: 'Jersey',
    jo: 'Jordan',
    kz: 'Kazakhstan',
    ke: 'Kenya',
    ki: 'Kiribati',
    xk: 'Kosovo',
    kw: 'Kuwait',
    kg: 'Kyrgyzstan',
    la: 'Laos',
    lv: 'Latvia',
    lb: 'Lebanon',
    ls: 'Lesotho',
    lr: 'Liberia',
    ly: 'Libya',
    li: 'Liechtenstein',
    lt: 'Lithuania',
    lu: 'Luxembourg',
    mo: 'Macau',
    mk: 'Macedonia',
    mg: 'Madagascar',
    mw: 'Malawi',
    my: 'Malaysia',
    mv: 'Maldives',
    ml: 'Mali',
    mt: 'Malta',
    mh: 'Marshall Islands',
    mq: 'Martinique',
    mr: 'Mauritania',
    mu: 'Mauritius',
    yt: 'Mayotte',
    mx: 'Mexico',
    fm: 'Micronesia',
    md: 'Moldova',
    mc: 'Monaco',
    mn: 'Mongolia',
    me: 'Montenegro',
    ms: 'Montserrat',
    ma: 'Morocco',
    mz: 'Mozambique',
    mm: 'Myanmar',
    na: 'Namibia',
    nr: 'Nauru',
    np: 'Nepal',
    nl: 'Netherlands',
    nc: 'New Caledonia',
    nz: 'New Zealand',
    ni: 'Nicaragua',
    ne: 'Niger',
    ng: 'Nigeria',
    nu: 'Niue',
    nf: 'Norfolk Island',
    kp: 'North Korea',
    mp: 'Northern Mariana Islands',
    no: 'Norway',
    om: 'Oman',
    pk: 'Pakistan',
    pw: 'Palau',
    ps: 'Palestine',
    pa: 'Panama',
    pg: 'Papua New Guinea',
    py: 'Paraguay',
    pe: 'Peru',
    ph: 'Philippines',
    pl: 'Poland',
    pt: 'Portugal',
    pr: 'Puerto Rico',
    qa: 'Qatar',
    re: 'Réunion',
    ro: 'Romania',
    ru: 'Russia',
    rw: 'Rwanda',
    bl: 'Saint Barthélemy',
    sh: 'Saint Helena',
    kn: 'Saint Kitts and Nevis',
    lc: 'Saint Lucia',
    mf: 'Saint Martin',
    pm: 'Saint Pierre and Miquelon',
    vc: 'Saint Vincent and the Grenadines',
    ws: 'Samoa',
    sm: 'San Marino',
    st: 'São Tomé and Príncipe',
    sa: 'Saudi Arabia',
    sn: 'Senegal',
    rs: 'Serbia',
    sc: 'Seychelles',
    sl: 'Sierra Leone',
    sg: 'Singapore',
    sx: 'Sint Maarten',
    sk: 'Slovakia',
    si: 'Slovenia',
    sb: 'Solomon Islands',
    so: 'Somalia',
    za: 'South Africa',
    kr: 'South Korea',
    ss: 'South Sudan',
    es: 'Spain',
    lk: 'Sri Lanka',
    sd: 'Sudan',
    sr: 'Suriname',
    sj: 'Svalbard and Jan Mayen',
    sz: 'Swaziland',
    se: 'Sweden',
    ch: 'Switzerland',
    sy: 'Syria',
    tw: 'Taiwan',
    tj: 'Tajikistan',
    tz: 'Tanzania',
    th: 'Thailand',
    tl: 'Timor-Leste',
    tg: 'Togo',
    tk: 'Tokelau',
    to: 'Tonga',
    tt: 'Trinidad and Tobago',
    tn: 'Tunisia',
    tr: 'Turkey',
    tm: 'Turkmenistan',
    tc: 'Turks and Caicos Islands',
    tv: 'Tuvalu',
    vi: 'U.S. Virgin Islands',
    ug: 'Uganda',
    ua: 'Ukraine',
    ae: 'United Arab Emirates',
    gb: 'United Kingdom',
    us: 'United States',
    uy: 'Uruguay',
    uz: 'Uzbekistan',
    vu: 'Vanuatu',
    va: 'Vatican City',
    ve: 'Venezuela',
    vn: 'Vietnam',
    wf: 'Wallis and Futuna',
    eh: 'Western Sahara',
    ye: 'Yemen',
    zm: 'Zambia',
    zw: 'Zimbabwe',
    ax: 'Åland Islands'
  }
}
